<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Información principal
            <f-icon icon="fa-regular fa-house" class="ml-2" />
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Name -->
        <div class="column is-6">
          <ValidationProvider
            rules="required"
            name="Nombre de la Vacante"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.name)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                icon="angle-right"
                :placeholder="newPlaceholder(fieldNames.name)"
                v-model="form.name"
                maxlength="64"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Duration -->
        <div class="column is-3">
          <ValidationProvider
            rules="required"
            name="Período"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.duration)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                icon="calendar-days"
                :placeholder="newPlaceholder(fieldNames.duration)"
                v-model="form.duration"
                expanded
              >
                <option
                  v-for="month in [3, 6, 9, 12, 15, 18, 21, 24]"
                  :key="month"
                  :value="`${month} meses`"
                >
                  {{ month === 1 ? `${month} mes` : `${month} meses` }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Positions -->
        <div class="column is-3">
          <ValidationProvider
            rules="required|numeric|min_value:1"
            name="Número de Posiciones"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.places)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                :placeholder="newPlaceholder(fieldNames.places)"
                icon="user-plus"
                type="number"
                v-model="form.places"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- State -->
        <div class="column is-4">
          <ValidationProvider
            rules="required"
            name="Estado"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.state)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                icon="map-pin"
                :placeholder="newPlaceholder(fieldNames.state)"
                v-model="stateSelected"
                expanded
                v-if="isMexico"
              >
                <option
                  v-for="(state, index) in states"
                  :key="`state-${index}`"
                  :value="state"
                >
                  {{ state.name }}
                </option>
              </b-select>

              <b-select
                v-else
                icon="map-pin"
                :placeholder="newPlaceholder(fieldNames.state)"
                v-model="stateSelected"
                expanded
              >
                <option
                  v-for="(province, index) in provinces"
                  :key="`province-${index}`"
                  :value="province"
                >
                  {{ province.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- City -->
        <div class="column is-4">
          <ValidationProvider
            rules="required"
            name="Ciudad"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.city)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                :placeholder="newPlaceholder(fieldNames.city)"
                icon="map-pin"
                v-model="citySelected"
                :disabled="stateCities.length === 0"
                expanded
              >
                <option
                  v-for="(city, index) in stateCities"
                  :key="`city-${index}`"
                  :value="city"
                >
                  {{ city.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Schedule -->
        <div class="column is-4">
          <ValidationProvider
            rules="required"
            name="Horario"
            v-slot="{ errors, valid }"
          >
            <b-tooltip
              style="width: 100%"
              label="Ejemplo: 8:00 a 14:00 hrs"
              position="is-bottom"
              animated
            >
              <b-field
                :label="renameField(fieldNames.schedule)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input
                  icon="clock"
                  :placeholder="newPlaceholder(fieldNames.schedule)"
                  v-model="form.schedule"
                />
              </b-field>
            </b-tooltip>
          </ValidationProvider>
        </div>

        <!-- Show Scholarship-->
        <div class="column mb-5">
          <b-field :label="renameField(fieldNames.showScholarship)">
            <b-switch
              class="mt-2 ml-40"
              :rounded="false"
              v-model="form.showScholarship"
            />
          </b-field>
        </div>

        <!-- Scholarship -->
        <div class="column is-4">
          <ValidationProvider
            rules="required|numeric"
            name="Beca"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.scholarship)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                :placeholder="newPlaceholder(fieldNames.scholarship)"
                icon="money-bill"
                v-model="form.scholarship"
                v-if="isMexico"
              />

              <b-input
                v-else
                :placeholder="newPlaceholder(fieldNames.scholarship)"
                icon="euro-sign"
                v-model="form.scholarship"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Modality -->
        <div class="column is-6">
          <ValidationProvider
            rules="required"
            name="Modalidad"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.modality)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                icon="square-user"
                :placeholder="newPlaceholder(fieldNames.modality)"
                v-model="form.modality"
                expanded
              >
                <option
                  v-for="modality in modalities"
                  :key="modality"
                  :value="modality"
                >
                  {{ modality }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import states from "@/lib/states";
import provinces from "@/lib/ES/provinces";
import { modalities, getStateCities, getDefaultVacancyFields } from "@/lib/vacancyDictionaries";

import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    generalData: {
      type: Object,
      required: false,
    },
    renameField: {
      type: Function,
      required: true,
    },
    newPlaceholder: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    // Set default values
    if(this.generalData) {
      this.form = this.generalData;
    }
  },
  data() {
    return {
      citySelected: null,
      provinces,
      states,
      stateSelected: null,
      form: {
        name: null,
        duration: null,
        places: 1,
        location: {
          state: null,
          city: null,
        },
        schedule: null,
        showScholarship: false,
        scholarship: null,
        modality: null,
      },
    };
  },
  watch: {
    stateSelected() {
      if (this.stateSelected.id == "00" && this.isSpain) {
        this.citySelected = this.provincesMunicipalities[0];
      }
    },
  },
  computed: {
    ...mapGetters(["companyId", "projectId"]),
    modalities() {
      return modalities;
    },
    stateCities() {
      return getStateCities(
        this.stateSelected?.id,
        process.env.VUE_APP_LOCATION
      );
    },
    isMexico() {
      return process.env.VUE_APP_LOCATION === "mx";
    },
    isSpain() {
      return process.env.VUE_APP_LOCATION === "es";
    },
    fieldNames() {
      return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
    }
  },
  methods: {
    async validateForm(){
      const valid = await this.$refs.observer.validate()

      if(valid){
        return this.form
      }
      return false
    },
  },
};
</script>

<style scoped>
@import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

.academicOptions--option {
  margin-top: 2px;
}
.language-table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  row-gap: 1rem;
}
</style>
