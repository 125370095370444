const superAdminRoles = ["superqueen", "manager"];
const externalRoles = ["hrmanager", "solicitant"];

const companyCreationEditionRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "designer-manager",
  "designer",
];

const projectCreationAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
];

const projectEditionAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
];

const vacancyCreationAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
];

const vacancyManagementAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
];

const manageAllCandidatesAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
  "recruiter",
];

const downloadCandidatesAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
  "recruiter",
];

const downloadAllCandidateInfoAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
  "recruiter",
];

const companyFieldsEditionAllowedRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
];

const allDashboardSeacherRoles = [
  "superqueen",
  "manager",
  "leader-recruiter-manager",
  "recruiter-manager",
];

const editProfileAllowedRoles = [
  "superqueen",
  "recruiter-manager",
  "recruiter",
];

export {
  superAdminRoles,
  externalRoles,
  companyCreationEditionRoles,
  projectCreationAllowedRoles,
  projectEditionAllowedRoles,
  vacancyCreationAllowedRoles,
  vacancyManagementAllowedRoles,
  manageAllCandidatesAllowedRoles,
  downloadCandidatesAllowedRoles,
  downloadAllCandidateInfoAllowedRoles,
  companyFieldsEditionAllowedRoles,
  allDashboardSeacherRoles,
  editProfileAllowedRoles,
};
