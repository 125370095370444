<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Killer Questions
            <f-icon icon="fa-regular fa-block-question" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Info -->
        <h2 class="subtitle is-6 mb-1">
          Preguntas que ayudarán a identificar a los candidatos ideales para la posición.
        </h2>

        <!-- List of questions -->
        <div class="column is-12">
          <div class=" columns is-multiline"
            v-for="(killerQuestion, index) in killerQuestions"
            :key="`question-${index}`"
          >
            <div class="column is-8">
              <ValidationProvider
                rules="required"
                :name="`killer-question-${index}`"
                v-slot="{ errors, valid }"
              >
                <b-field
                  :label="`${index + 1}.- ${killerQuestion.question}`"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="killerQuestion.question"></b-input>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-3">
              <ValidationProvider
                rules="required"
                :name="`Tipo de respuesta ${index}`"
                v-slot="{ errors, valid }"
              >
                <b-field
                  label="Tipo de respuesta"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-select
                    v-model="killerQuestion.questionType"
                    expanded
                  >
                    <option
                      v-for="(option, index) in killerQuestionOptions"
                      :key="`option-${index}`"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-1 is-flex align-flex-end pb-4">
              <b-button
                type="is-danger"
                @click="popKillerQuestion(index)"
                outlined
                icon-left="trash"
              />
            </div>

            <div
              class="column is-8"
              v-if="hasOptionsValue.includes(killerQuestion.questionType)"
            >
              <div
                v-for="(option, index) in killerQuestion.optionsValues"
                :key="`option-${index}`"
                class="mt-1"
              >
                <ValidationProvider
                  rules="required"
                  :name="`opción${index}`"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :label="`Opción ${index + 1}`"
                    :type="{ 'is-danger': errors.length > 0, 'is-success': valid }"
                    :message="errors[0]"
                  >
                    <b-input
                      v-model="killerQuestion.optionsValues[index]"
                      aria-label="Ingresa una opción"
                    />
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <div
              class="column is-4 is-flex is-justify-content-flex-start is-align-self-flex-start pt-6 is-align-items-center"
              v-if="hasOptionsValue.includes(killerQuestion.questionType)"
            >
              <b-button
                @click="killerQuestion.optionsValues.push('')"
                icon-left="plus"
                type="is-blue"
                outlined
              />

              <div class="mr-4 ml-4">Opciones</div>

              <b-button
                @click="killerQuestion.optionsValues.pop()"
                icon-left="minus"
                type="is-danger"
                outlined
              />
            </div>
          </div>
        </div>

        <!-- Add KillerQuestion -->
        <div class="column is-3 p-0">
            <b-button
              expanded
              type="is-primary"
              @click="addKillerQuestion"
            >
              <f-icon icon="fa-regular fa-square-dashed-circle-plus" class="mr-2"/>
              Agregar pregunta
            </b-button>
          </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import killerQuestionOptions from "@/lib/killerQuestions";

  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    props: {
      killerQuestionsData: {
        type: Array,
        default: () => [],
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
    },
    mounted() {
      // Set default values
      if(this.killerQuestionsData) {
        this.killerQuestions = this.killerQuestionsData;
      }
    },
    data() {
      return {
        killerQuestionOptions,
        killerQuestions: [],
        hasOptionsValue: ["single-option", "multiple-option"],
        deletedQuestions: [],
      };
    },
    watch: {
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
    },
    methods: {
      addKillerQuestion() {
        this.killerQuestions.push({
          question: "",
          questionType: "",
          optionsValues: [""],
        });
      },
      popKillerQuestion(index) {
        this.killerQuestions.splice(index, 1);
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return {
            killerQuestions: this.killerQuestions,
          }
        }
        return false
      },
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
</style>
