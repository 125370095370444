<template>
  <div>
    <!-- General user -->
    <div class="profile-box">
      <CandidateGeneralData :profile="Profile" @editGeneralProfile="editingGeneralProfile = true"
        v-if="!editingGeneralProfile" />
      <EditGeneralProfileForm v-if="editingGeneralProfile" :profile="Profile"
        @generalProfileUpdated="handleProfileUpdate($event, 'general')" />
    </div>

    <hr />

    <!-- Academic user -->
    <div class="profile-box">
      <CandidateAcademicData :profile="Profile" @editAcademicProfile="editingAcademicProfile = true"
        v-if="!editingAcademicProfile" />
      <EditAcademicProfile v-if="editingAcademicProfile" :profile="Profile"
        @academicProfileUpdated="handleProfileUpdate($event, 'academic')" />
    </div>

    <hr />

    <!-- Skills and knowledge user -->
    <div class="profile-box">
      <CandidateSkillsKnowledges :profile="Profile" @editskillsknowledgesForm="editingSkillsKnowProfile = true"
        v-if="!editingSkillsKnowProfile" />
      <EditSkillsKnowledgesForm v-if="editingSkillsKnowProfile" :profile="Profile"
        @SkillsKnowledgesProfileUpdated="handleProfileUpdate($event, 'skillsknowledges')" />
    </div>


    <!-- Professional user -->
    <div class="profile-box">
      <CandidateProfessionalData :profile="Profile" @editprofessionaldataform="editingProfileProffessional = true"
        v-if="!editingProfileProffessional" />
      <EditProfessionalDataForm v-if="editingProfileProffessional" :profile="Profile"
        @ProfileProfessionalUpdated="handleProfileUpdate($event, 'professionaldata')" />

    </div>

    <hr />

    <!-- Vacancy found source -->
    <CandidateVacancySource :application="application" />
    <hr />

    <!-- Additional Comments -->
    <CandidateAdditionalComments :profile="profile" />
  </div>
</template>

<script>
import CandidateGeneralData from './general-data/CandidateGeneralData.vue';
import EditGeneralProfileForm from './general-data/EditGeneralProfileForm.vue';
import CandidateAcademicData from './academic-data/CandidateAcademicData.vue';
import EditAcademicProfile from './academic-data/EditAcademicProfile.vue';
import CandidateAdditionalComments from './CandidateAdditionalComments.vue';
import CandidateVacancySource from './CandidateVacancySource.vue';
import CandidateSkillsKnowledges from './skills-knowledges/CandidateSkillsKnowledges.vue';
import EditSkillsKnowledgesForm from './skills-knowledges/EditSkillsKnowledgesForm.vue';
import CandidateProfessionalData from './professional-data/CandidateProfessionalData.vue';
import EditProfessionalDataForm from './professional-data/EditProfessionalDataForm.vue';


export default {
  props: {
    application: {
      default: () => ({}),
      required: true,
    },
  },
  components: {
    CandidateGeneralData,
    EditGeneralProfileForm,
    CandidateAcademicData,
    EditAcademicProfile,
    CandidateSkillsKnowledges,
    EditSkillsKnowledgesForm,
    CandidateProfessionalData,
    CandidateVacancySource,
    CandidateAdditionalComments,
    EditProfessionalDataForm,
  },
  data() {
    return {
      editingGeneralProfile: false,
      editingAcademicProfile: false,
      editingSkillsKnowProfile: false,
      editingProfileProffessional: false,
      Profile: { ...this.application.profile },
    };
  },
  computed: {
    profile() {
      return this.application.profile;
    },
  },
  methods: {
    handleProfileUpdate(updatedProfile, type) {
      this.Profile = { ...this.Profile, ...updatedProfile };
      const editingStates = {
        general: 'editingGeneralProfile',
        academic: 'editingAcademicProfile',
        skillsknowledges: 'editingSkillsKnowProfile',
        professionaldata: 'editingProfileProffessional',
      };
      if (editingStates[type]) {
        this[editingStates[type]] = false;
      }
    }
  },

};
</script>



<style lang="scss" scoped>
.profile-box {
  border-radius: 15px;
  padding: .5rem;
  margin-bottom: 2rem;
  background-color: white;
}
</style>
