<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Habilidades y Conocimientos
            <f-icon icon="fa-regular fa-books" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Habilities -->
        <div class="column is-12">
          <!-- title -->
          <h1 class="title is-5">{{ renameField(fieldNames.skills) }}</h1>
          <!-- Info -->
          <h1 class="subtitle is-6">
            Habilidades, competencias y caracteristicas personales necesarias para la posición.
          </h1>

          <!-- List of habilties -->
          <div
            v-for="(skill, index) in form.skills"
            :key="`${index + 1}.`"
            class="columns is-multiline align-flex-end is-12"
          >
            <div class="column is-5">
              <b-field
                style="height: 3rem"
                horizontal
                :label="`${index + 1}.`"
              >
                <b-input
                  :placeholder="newPlaceholder(fieldNames.skills)"
                  icon="award"
                  v-model="skill.name"
                  type="text"
                />
              </b-field>
            </div>

            <div class="column is-1 pb-20 pl-12">
              <b-button
                outlined
                type="is-danger"
                icon-left="trash"
                @click="deleteSkill(index)"
              />
            </div>
          </div>

          <!-- Add habilities -->
          <div class="column is-3 p-0">
            <b-button
              expanded
              type="is-primary"
              @click="addSkill"
            >
              <f-icon icon="fa-regular fa-screwdriver-wrench" class="mr-2"/>
              Agregar habilidad
            </b-button>
          </div>
        </div>

        <!-- Knowledges -->
        <div class="column is-12">
          <!-- Title -->
          <h1 class="title is-5">{{ renameField(fieldNames.knowledges) }}</h1>
          <!-- Info -->
          <h1 class="subtitle is-6">
            Conocimientos como paquetería, programas, equipos, etc., necesarias para la posición.
          </h1>

          <!-- List of knowledges -->
          <div
            v-for="(knowledge, index) in form.knowledges"
            :key="`${index + 1}.`"
            class="columns is-multiline align-flex-end mb-0"
          >
            <!-- Knowledge -->
            <div class="column is-6">
              <ValidationProvider
                rules="required"
                name="Conocimiento"
                v-slot="{ errors, valid }"
              >
                <b-field
                  style="height: 4rem"
                  horizontal
                  :label="`${index + 1}.`"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-autocomplete
                    :placeholder="newPlaceholder(fieldNames.knowledges)"
                    icon="cube"
                    v-model="knowledge.name"
                    maxlength="64"
                    :data="filteredKnowledges"
                    :open-on-focus="true"
                    :clearable="true"
                    field="name"
                    @focus="focusedKnowledge(index)"
                    @select="(option) => (selectedKnowledge = option)"
                  >
                  </b-autocomplete>
                </b-field>
              </ValidationProvider>
            </div>

            <!-- Level -->
            <div class="column is-5">
              <ValidationProvider
                rules="required"
                name="Nivel conocimiento"
                v-slot="{ errors, valid }"
              >
                <b-field
                  style="height: 4rem"
                  horizontal
                  label="Nivel"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-select
                    v-model="knowledge.level"
                    icon="signal-alt-3"
                    expanded
                  >
                    <option
                      v-for="knowledgeLevel in knowledgeLevels"
                      :key="`${knowledgeLevel}`"
                      :value="knowledgeLevel"
                    >
                      {{ knowledgeLevel }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-1 pb-36 pl-12">
              <b-button
                outlined
                type="is-danger"
                icon-left="trash"
                @click="deleteKnowledge(index)"
              />
            </div>
          </div>

           <!-- Add knowledges -->
           <div class="column is-3 p-0">
            <b-button
              expanded
              type="is-primary"
              @click="addKnowledge"
            >
              <f-icon icon="fa-regular fa-book" class="mr-2"/>
              Agregar conocimiento
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import {
    getDefaultVacancyFields,
    knowledgeLevels,
  } from "@/lib/vacancyDictionaries";

  import {
    getKnowledges,
  } from "../../api/vacancy";
  
  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    props: {
      habilities: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.habilities) {
        this.form = this.habilities;
      }
    },
    data() {
      return {
        knowledges: [],
        knowledgeIndex: 0,
        knowledgeLevels,
        form: { 
          skills: [],
          knowledges: [],
        },
      };
    },
    watch: {
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      filteredKnowledges() {
        if (this.form.knowledges[this.knowledgeIndex].name) {
          return this.knowledges.filter((knowledge) => {
            if (this.knowledgeIndex >= 0) {
              return (
                knowledge.name.toLowerCase().indexOf(this.form.knowledges[this.knowledgeIndex].name.toLowerCase()) >= 0
              );
            }
          });
        } else return this.knowledges;
      },
    },
    methods: {
      async mountedPromises() {
        this.knowledges = await getKnowledges(this.companyId);
      },
      addSkill() {
        this.form.skills.push({
          name: "",
        });
      },
      deleteSkill(index) {
        this.form.skills.splice(index, 1);
      },
      addKnowledge() {
        if (this.form.knowledges.length < 10) {
          this.form.knowledges.push({
            name: "",
            level: "",
          });
        } else
          this.$buefy.toast.open({
            message: "Límite máximo de conocimientos alcanzado",
            type: "is-warning",
          });
      },
      deleteKnowledge(index) {
        this.form.knowledges.splice(index, 1);
      },
      focusedKnowledge(index) {
        this.knowledgeIndex = index;
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
</style>
