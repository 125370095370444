<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Idiomas de vacante 
            <f-icon icon="fa-regular fa-language" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
       <!-- Nivel de inglés -->
       <div class="column is-full">
          <div class="language-table">
            <p class="is-size-5 has-text-left has-text-weight-semibold">
              {{ form.englishLevels.language }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Deseable</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Básico</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Intermedio</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Avanzado</p>
            <p class="is-size-6 has-text-weight-medium has-text-info-dark">Representativa</p>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Escritura</p>
            <b-radio
              name="englishWriting"
              native-value="Deseable"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-radio
              name="englishWriting"
              native-value="Básico"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-radio
              name="englishWriting"
              native-value="Intermedio"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-radio
              name="englishWriting"
              native-value="Avanzado"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="writing"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Lectura</p>
            <b-radio
              name="englishReading"
              native-value="Deseable"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-radio
              name="englishReading"
              native-value="Básico"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-radio
              name="englishReading"
              native-value="Intermedio"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-radio
              name="englishReading"
              native-value="Avanzado"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="reading"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Escucha</p>
            <b-radio
              name="englishListening"
              native-value="Deseable"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-radio
              name="englishListening"
              native-value="Básico"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-radio
              name="englishListening"
              native-value="Intermedio"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-radio
              name="englishListening"
              native-value="Avanzado"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="listening"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Conversación</p>
            <b-radio
              name="englishSpeaking"
              native-value="Deseable"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-radio
              name="englishSpeaking"
              native-value="Básico"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-radio
              name="englishSpeaking"
              native-value="Intermedio"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-radio
              name="englishSpeaking"
              native-value="Avanzado"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="speaking"
              required
            ></b-checkbox>
          </div>
        </div>

        <!-- Other languages -->
        <div
          class="column is-full"
          v-for="(language, index) in form.otherLanguages"
          :key="`language-${index}`"
        >
          <div class="column is-4 is-flex pb-0 mt-2">
            <b-input
              class="mr-2"
              style="width: 100%"
              maxlength="24"
              v-model="form.otherLanguages[index].language"
            ></b-input>

            <b-button
              type="is-danger"
              @click="removeLenguaje(index)"
            >
              <f-icon icon="fa-regular fa-trash" />
            </b-button>
          </div>

          <div class="language-table">
            <p class="is-size-5 has-text-weight-semibold">
              {{ form.otherLanguages[index].language }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Deseable</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Básico</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Intermedio</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">Avanzado</p>
            <p class="is-size-6 has-text-weight-medium has-text-info-dark">Representativa</p>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Escritura</p>
            <b-radio
              native-value="Deseable"
              required
              v-model="form.otherLanguages[index].writing"
              :name="`lenguage${index}Writing`"
            ></b-radio>
            <b-radio
              native-value="Básico"
              required
              v-model="form.otherLanguages[index].writing"
              :name="`lenguage${index}Writing`"
            ></b-radio>
            <b-radio
              native-value="Intermedio"
              required
              :name="`lenguage${index}Writing`"
              v-model="form.otherLanguages[index].writing"
            ></b-radio>
            <b-radio
              native-value="Avanzado"
              required
              :name="`lenguage${index}Writing`"
              v-model="form.otherLanguages[index].writing"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="writing"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Lectura</p>
            <b-radio
              native-value="Deseable"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-radio
              native-value="Básico"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-radio
              native-value="Intermedio"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-radio
              native-value="Avanzado"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="reading"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Escucha</p>
            <b-radio
              native-value="Deseable"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-radio
              native-value="Básico"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-radio
              native-value="Intermedio"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-radio
              native-value="Avanzado"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="listening"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">Conversación</p>
            <b-radio
              native-value="Deseable"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-radio
              native-value="Básico"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-radio
              native-value="Intermedio"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-radio
              native-value="Avanzado"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="speaking"
              required
            ></b-checkbox>
          </div>
        </div>

        <!-- Add language -->
        <div class="column is-3">
          <b-button
            expanded
            type="is-primary"
            @click="addLenguaje"
          >
            <f-icon icon="fa-regular fa-language" class="mr-2"/>
            Agregar idioma
          </b-button>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import {
    getDefaultVacancyFields,
  } from "@/lib/vacancyDictionaries";

  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    props: {
      languages: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.languages) {
        this.form = this.languages;
      }
    },
    data() {
      return {
        form: {
          englishLevel: null,
          englishLevels: {
            language: 'Inglés',
            listening: "Intermedio",
            reading: "Intermedio",
            speaking: "Intermedio",
            writing: "Intermedio",
            representativeSkill: ['speaking'],
          },
          otherLanguages: [],
        },
      };
    },
    watch: {
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
    },
    methods: {
      async mountedPromises() {
      },
      addLenguaje() {
        this.form.otherLanguages.push({
          language: "",
          writing: "Deseable",
          listening: "Deseable",
          reading: "Deseable",
          speaking: "Deseable",
          representativeSkill: ['speaking'],
        });
      },
      removeLenguaje(index) {
        this.form.otherLanguages.splice(index, 1);
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          this.form.englishLevel = this.form.englishLevels.speaking;
          return this.form
        }
        return false
      },
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .language-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
</style>
