<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Datos de contactos
            <f-icon icon="fa-regular fa-id-badge" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Main author -->
        <div class="column is-9">
          <p class="subtitle is-6 is-flex is-flex-direction-column">
            <b class="mb-2">Contacto seleccionado</b> 
            {{ getAuthorName(form.author) }}
          </p>
        </div>

        <!-- Select additional authors -->
        <div class="column is-3 has-text-right">
          <p class="subtitle is-6 is-flex is-flex-direction-column">
            <b class="mb-2">Agregar otros contactos</b>
            <b-tooltip
              multilined
              type="is-blue"
              position="is-top"
              label="Desmarcar esta opción elimina los contactos adicionales agregados"
            >
              <b-field>
                <b-switch v-model="otherSolicitants" />
              </b-field>
            </b-tooltip>
          </p>
        </div>

        <!-- Available solicitants -->
        <div
          class="column is-12"
          v-if="!otherSolicitants"
        >
        <div class="columns is-multiline">  
          <div class="column is-12">
            <b class="is-size-6">Contactos disponibles</b>
            <div
              class="px-1"
              style="max-height: 20rem; min-height: auto; overflow-y: scroll"
            >
              <div
                class="card my-3"
                v-for="(contact, index) in authors"
                :key="`author-${index}`"
              >
                <div class="card-content">
                  <div class="columns is-multiline">
                    <div class="column is-8">
                      <p>
                        <b-icon
                          type="is-info"
                          icon="user"
                        />{{ contact.fullName }}
                      </p>
                      <p>
                        <b-icon
                          type="is-info"
                          icon="envelope"
                          class="mr-1"
                        />{{ contact.email }}
                      </p>
                    </div>
                    <div class="column is-4 has-text-right">
                      <b-button
                        type="is-primary"
                        rounded
                        icon-left="hand-point-left"
                        label="Seleccionar"
                        @click="setAuthor(contact.id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!-- Another available solicitants -->
        <div
          class="column is-12"
          v-else
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <b class="is-size-6">Contactos adicionales disponibles</b>
              <div
                class="px-1"
                style="max-height: 20rem; min-height: auto; overflow-y: scroll"
              >
                <div
                  class="card my-3"
                  v-for="(contact, index) in authors"
                  :key="`contact-${index}`"
                >
                  <div class="card-content">
                    <div class="columns is-multiline">
                      <div class="column is-8">
                        <p>
                          <b-icon
                            type="is-info"
                            icon="user"
                          />{{ contact.fullName }}
                        </p>
                        <p>
                          <b-icon
                            type="is-info"
                            icon="envelope"
                            class="mr-1"
                          />{{ contact.email }}
                        </p>
                      </div>
                      <div class="column is-4 has-text-right">
                        <b-button
                          :disabled="
                            form.additionalAuthors.includes(contact.id) || form.author === contact.id
                          "
                          type="is-primary"
                          rounded
                          icon-right="plus"
                          label="Agregar"
                          @click="addCompanyUser(contact.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <b class="is-size-6">Contactos adicionales asignados</b>
              <div
                class="px-2"
                style="max-height: 20rem; min-height: auto; overflow-y: scroll"
              >
                <div
                  class="card my-3"
                  v-for="(author, index) in form.additionalAuthors"
                  :key="`author-${index}`"
                >
                  <div class="card-content">
                    <div class="columns is-multiline">
                      <div class="column is-8">
                        <p>
                          <b-icon
                            type="is-primary"
                            icon="user"
                          />{{ getAuthorName(author) }}
                        </p>
                        <p>
                          <b-icon
                            type="is-primary"
                            icon="envelope"
                            class="mr-1"
                          />
                          {{ getAuthorEmail(author) }}
                        </p>
                      </div>
                      <div class="column is-4 has-text-right">
                        <b-button
                          class="mt-1 mr-1"
                          type="is-danger is-ghost"
                          rounded
                          outlined
                          icon-right="xmark"
                          size="is-small"
                          @click="popCompanyUser(index)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <b-message
                  v-if="!form.additionalAuthors.length"
                  type="is-info"
                  class="mt-2"
                >
                  Asigna por lo menos 1 contacto adicional
                </b-message>
              </div>
            </div>
          </div>
        </div>

        <!-- Add contact -->
        <div class="column is-3 p-0 ml-3 mt-2">
          <b-button
            expanded
            type="is-primary"
            @click="openCreateDialog"
          >
            <f-icon icon="fa-regular fa-user-plus" class="mr-2"/>
            Agregar usuario
          </b-button>
        </div>

        <!-- Privacy advisement -->
        <div class="column is-12">
          <b-message type="is-primary">
            Estos datos son confidenciales y son de uso exclusivo de Pro Meritum.
          </b-message>
        </div>
      </div>
    </div>

    <UserCreate
      contactUsers
      :company="companyId"
      @updated="$emit('usersUpdated')"
      ref="create"
    />
  </ValidationObserver>
</template>

<script>
  import {
    getDefaultVacancyFields,
  } from "@/lib/vacancyDictionaries";

  import { externalRoles } from "@/lib/permissions";

  import { mapGetters } from "vuex";

  import UserCreate from "@/components/modals/UserCreate";

  export default {
    components: {
      UserCreate,
    },
    props: {
      solicitantsData: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
      authors: {
        type: Array,
        required: true,
      },
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.solicitantsData) {
        this.form = this.solicitantsData;
      }
    },
    data() {
      return {
        otherSolicitants: false,
        form: {
          author: '',
          additionalAuthors: []
        },
      };
    },
    watch: {
      otherSolicitants(val) {
        if (!val) {
          this.form.additionalAuthors = [];
        }
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      user() {
        return this.$store.state.session.user;
      },
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
    },
    methods: {
      async mountedPromises() {
        this.mountedAuthor();
      },
      mountedAuthor() {
        if (this.$hasPermissionsByRole(externalRoles)) {
          this.form.author = [this.user.id];
        }
      },
      openCreateDialog() {
        this.$refs.create.open();
      },
      setAuthor(id) {
        this.form.author = id;
      },
      addCompanyUser(id) {
        this.form.additionalAuthors.push(id);
      },
      popCompanyUser(index) {
        this.form.additionalAuthors.splice(index, 1);
      },
      getAuthorName(id) {
        const author = this.authors.find((user) => user.id === id);
        return author?.fullName ? author.fullName : "Sin asignar";
      },
      getAuthorEmail(id) {
        const author = this.authors.find((user) => user.id === id);
        return author?.email;
      },
      async validateForm(){
        if(this.form.author){
          return this.form
        }
        return false
      },
    },
  };
</script>

<style >

</style>
