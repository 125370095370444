<template>
  <div>
    <!-- Encabezado con botón de guardar -->
    <div class="column pl-0 pt-0 mb-2 is-full is-flex is-align-content-center">
      <div class="title is-4 mb-0 has-text-blue">
        {{ skillsKnowledgesTranslation.editSkillsKnowledgesProfile || skillsKnowledgesTranslation.skillsKnowledgeProfile
        }}
      </div>
      <!-- Botón para guardar cambios -->
      <a class="ml-2" @click="updateSkillsKnowledgesProfile">
        <b-tooltip animated class="is-flex is-align-items-end" label="Guardar cambios" position="is-top"
          type="is-success">
          <b-icon class="icons" icon="floppy-disk"></b-icon>
        </b-tooltip>
      </a>
    </div>
    <!-- Formulario -->
    <ValidationObserver ref="observer">
      <div class="columns is-multiline">
        <!-- Skills (habilidades) -->
        <div class="column is-12">
          <div v-for="(skill, index) in form.skills" :key="index" class="columns is-multiline">
            <!-- Nombre de la habilidad -->
            <div class="column is-6">
              <ValidationProvider :rules="`required`" name="Habilidad" v-slot="{ errors, valid }">
                <b-field label="Habilidad" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="skill.name" placeholder="Ingresa una habilidad"></b-input>
                </b-field>
              </ValidationProvider>
            </div>

            <!-- Nivel de la habilidad -->
            <div class="column is-6">
              <ValidationProvider :rules="`required`" name="Nivel" v-slot="{ errors, valid }">
                <b-field label="Nivel" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-select v-model="skill.level" placeholder="Selecciona un nivel">
                    <option v-for="level in levels" :key="level" :value="level">{{ level }}</option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- Interests (intereses) -->
        <div class="column is-12">
          <h3 class="subtitle is-5">Intereses</h3>
          <div v-for="(interest, index) in form.interests" :key="index">
            <ValidationProvider :rules="`required`" name="Interés" v-slot="{ errors, valid }">
              <b-field label="Interés" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                <b-input v-model="form.interests[index]" placeholder="Ingresa un interés"></b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <!-- English Level -->
        <div class="column is-6">
          <ValidationProvider :rules="`required`" name="Nivel de inglés" v-slot="{ errors, valid }">
            <b-field label="Nivel de inglés" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
              <b-select v-model="form.englishLevel" placeholder="Selecciona el nivel de inglés">
                <option v-for="level in englishLevels" :key="level" :value="level">{{ level }}</option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
        <!-- Excel Level -->
        <div class="column is-6">
          <ValidationProvider :rules="`required`" name="Nivel de Excel" v-slot="{ errors, valid }">
            <b-field label="Nivel de Excel" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
              <b-select v-model="form.excelLevel" placeholder="Selecciona el nivel de Excel">
                <option v-for="level in excelLevels" :key="level" :value="level">{{ level }}</option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { updateSkillsProfile } from '@/api/candidate';
import { levels, englishLevels, excelLevels } from '@/lib/applicationDictionaries';

export default {
  props: {
    profile: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      form: {
        skills: this.profile.skills || [{ name: '', level: '' }],
        interests: [
          this.profile.interestOne || '',
          this.profile.interestTwo || '',
          this.profile.interestThree || ''
        ],
        englishLevel: this.profile.englishLevel || '',
        excelLevel: this.profile.excelLevel || '',
      },
      levels: [],
      englishLevels: [],
      excelLevels: [],
      isLoading: false,
    };
  },
  methods: {
    detectLanguage(value, dictionary) {
      for (let lang in dictionary) {
        if (dictionary[lang].includes(value)) {
          return lang;
        }
      }
      return 'mx';
    },
    loadDynamicValues() {
      const levelLang = this.detectLanguage(this.form.skills[0]?.level || '', levels);
      this.levels = levels[levelLang];

      // Detectamos el idioma para 'englishLevel'
      const englishLang = this.detectLanguage(this.form.englishLevel || '', englishLevels);
      this.englishLevels = englishLevels[englishLang];

      // Detectamos el idioma para 'excelLevel'
      const excelLang = this.detectLanguage(this.form.excelLevel || '', excelLevels);
      this.excelLevels = excelLevels[excelLang];
    },
    async updateSkillsKnowledgesProfile() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.toast.open({
          message: 'Uno o más campos están incompletos o son inválidos.',
          type: 'is-danger',
        });
        return;
      }
      try {
        this.isLoading = true;
        const payload = {
          ...this.form,
          id: this.$route.params.id,
          skills: this.form.skills || [{ name: '', level: '' }],
          interestOne: this.form.interests[0] || '',
          interestTwo: this.form.interests[1] || '',
          interestThree: this.form.interests[2] || '',
          englishLevel: this.form.englishLevel || '',
          excelLevel: this.form.excelLevel || '',
        };

        await updateSkillsProfile(payload);
        this.$buefy.toast.open({
          message: 'Perfil de competencias y conocimientos actualizado con éxito.',
          type: 'is-success',
        });
        this.$emit('SkillsKnowledgesProfileUpdated', payload);
      } catch (error) {
        const message = error.response?.data?.message || 'Error al actualizar el perfil de competencias y conocimientos.';
        this.$buefy.toast.open({
          message,
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    skillsKnowledgesTranslation() {
      return this.$t('screens.candidate.detail');
    },
  },

  mounted() {
    this.loadDynamicValues();
  },

  watch: {
    'form.skills': {
      handler() {
        this.loadDynamicValues();
      },
      deep: true
    },
    'form.englishLevel': {
      handler() {
        this.loadDynamicValues();
      },
    },
    'form.excelLevel': {
      handler() {
        this.loadDynamicValues();
      },
    }
  }
};
</script>
