<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Idiomas de la vacante
            <f-icon icon="fa-regular fa-globe-pointer" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Info -->
        <h2 class="subtitle is-6 mb-1">
          Lenguajes en que será mostrada la información de la vacante y el formulario de aplicación en el portal de vacantes.
        </h2>

        <!-- Vacancy Form Language -->
        <div class="column is-3">
          <ValidationProvider
            rules="required"
            name="Idiomas de vacante"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="`Idiomas de vacante`"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-checkbox
                v-for="(vacancyFormLanguageOption, index) in vacancyFormLanguageOptions"
                :key="`vacancy-form-language-${index}`"
                class="mr-5 mt-2"
                v-model="form.vacancyFormLanguages"
                :native-value="vacancyFormLanguageOption.value"
              >
                {{ vacancyFormLanguageOption.text }}
              </b-checkbox>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Main Vacancy Form Language -->
        <div class="column is-4">
          <ValidationProvider
            rules="required"
            name="Idioma principal de vacante"
            v-slot="{ errors, valid }"
            v-if="form.vacancyFormLanguages.length > 1"
          >
            <b-field
              :label="`Idioma principal de vacante`"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="form.mainVacancyFormLanguage"
                :native-value="form.mainVacancyFormLanguage"
                expanded
              >
                <option
                  v-for="(vacancyFormLanguageOption, index) in vacancyFormLanguageOptions"
                  :key="`main-vacancy-form-language-${index}`"
                  :value="vacancyFormLanguageOption.value"
                >
                  {{ vacancyFormLanguageOption.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        form: {
          vacancyFormLanguages: [process.env.VUE_APP_LOCATION],
          mainVacancyFormLanguage: process.env.VUE_APP_LOCATION,
        },
        vacancyFormLanguageOptions: [
          {
            text: "Español",
            value: process.env.VUE_APP_LOCATION,
          }, 
          {
            text: "Inglés",
            value: "en",
          }
        ],
      };
    },
    watch: {
      'form.vacancyFormLanguages': function(){
        if(this.form.vacancyFormLanguages.length === 1){
          this.form.mainVacancyFormLanguage = this.form.vacancyFormLanguages[0]
        }
      },
      'form.mainVacancyFormLanguage': function(){
        this.$emit('updateMainVacancyFormLanguage', this.form.mainVacancyFormLanguage)
      }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

</style>
