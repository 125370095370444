<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Acerca del reclutamiento
            <f-icon icon="fa-regular fa-user-magnifying-glass" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Requisition schema -->
        <div class="column is-6">
          <ValidationProvider
            rules="required"
            name="Esquema de requisición"
            v-slot="{ errors, valid }"
          >
            <b-field 
              label="Esquema de requisición"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="form.schemaType"
                icon="file-contract"
                expanded
              >
                <option
                  v-for="(schema, index) in servicesSchema"
                  :key="`schema-${index}`"
                  :value="schema"
                >
                  {{ schema }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Service type -->
        <div class="column is-6">
          <ValidationProvider
            rules="required"
            name="Tipo de Servicio"
            v-slot="{ errors, valid }"
          >
            <b-field 
              label="Tipo de Servicio"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="form.serviceType"
                icon="list-radio"
                expanded
              >
                <option
                  v-for="(service, index) in recruitmentServices"
                  :key="`service-${index}`"
                  :value="service"
                >
                  {{ service }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Service detail -->
        <div class="column is-6"
          v-if="form.serviceType && form.serviceType != 'Reclutamiento tradicional'"
        >
        <ValidationProvider
            rules="required"
            name="Detalle de servicio"
            v-slot="{ errors, valid }"
          >
            <b-field 
              label="Detalle de servicio"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="form.serviceDetail"
                icon="game-console-handheld"
                expanded
                required
              >
                <option
                  v-for="(detail, index) in alternativeRecruitmentServices"
                  :key="`detail-${index}`"
                  :value="detail"
                >
                  {{ detail }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Service claim -->
        <div class="column is-6">
          <ValidationProvider
            rules="required"
            name="Tipo de reclamación"
            v-slot="{ errors, valid }"
          >
            <b-field 
              label="Tipo de reclamación"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="form.claimType"
                icon="cards-blank"
                expanded
                required
              >
                <option
                  v-for="(claim, index) in servicesClaims"
                  :key="`claim-${index}`"
                  :value="claim"
                >
                  {{ claim }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import {
    getDefaultVacancyFields,
  } from "@/lib/vacancyDictionaries";

  import { mapGetters } from "vuex";
  import servicesSchema from "@/lib/servicesSchema";
  import servicesClaims from "@/lib/servicesClaims";
  import alternativeRecruitmentServices from "@/lib/alternativeRecruitmentServices";
  import recruitmentServices from "@/lib/recruitmentServices";

  export default {
    components: {
    },
    props: {
      recruitment: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.recruitment) {
        this.form = this.recruitment;
      }
    },
    data() {
      return {
        servicesSchema,
        servicesClaims,
        alternativeRecruitmentServices,
        recruitmentServices,
        form: {
          schemaType: null,
          serviceType: null,
          serviceDetail: null,
          claimType: null,
        },
      };
    },
    watch: {
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      user() {
        return this.$store.state.session.user;
      },
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
    },
    methods: {
      async mountedPromises() {
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
    },
  };
</script>

<style >

</style>
