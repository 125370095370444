<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-full is-flex is-align-content-center">
        <div class="title is-4 mb-0 has-text-blue">
          {{ skillsKnowledgesTranslation.skillsKnowledgeProfile }}
        </div>
        <!-- Botón de Edición-->
        <a v-if="canEditProfile" class="ml-2" @click="$emit('editskillsknowledgesForm')">
          <b-tooltip animated class="is-flex is-align-items-end" label="Editar perfil general" position="is-top"
            type="is-success">
            <b-icon class="icons" icon="pen-to-square"></b-icon>
          </b-tooltip>
        </a>
      </div>
      <!-- Skills -->
      <div class="column is-half">
        <div class="label">
          {{ skillsKnowledgesTranslation.skills }}
        </div>

        <div>
          <b-field grouped group-multiline>
            <div v-for="(skill, index) in profile.skills" :key="`skill-${index}`" class="control">
              <b-taglist attached>
                <b-tag type="is-blue">
                  {{ skill.name }}
                </b-tag>
                <b-tag type="is-dark">
                  {{ skill.level }}
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>
      </div>

      <!-- knowledges -->
      <div class="column is-half">
        <div class="label">
          {{ skillsKnowledgesTranslation.interests }}
        </div>

        <div class="overflow-ellipsis">
          {{ profile.interestOne ?? "Sin asignar" }}
        </div>

        <div class="overflow-ellipsis">
          {{ profile.interestTwo ?? "Sin asignar" }}
        </div>

        <div class="overflow-ellipsis">
          {{ profile.interestThree ?? "Sin asignar" }}
        </div>
      </div>

      <!-- English level -->
      <div class="column is-6">
        <div class="label">
          {{ skillsKnowledgesTranslation.englishLevel }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.englishLevel }}
        </div>
      </div>

      <!-- excel level -->
      <div class="column is-6">
        <div class="label">
          {{ skillsKnowledgesTranslation.excelLevel }}
        </div>
        <div class="overflow-ellipsis">
          {{ profile.excelLevel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { editProfileAllowedRoles } from '@/lib/permissions';

export default {
  props: {
    profile: {
      default: () => ({}),
      required: true
    },
  },
  data() {
    return {
    };
  },
  computed: {
    skillsKnowledgesTranslation() {
      return this.$t("screens.candidate.detail");
    },
    canEditProfile() {
      const userRole = this.$store.state.session.user.role;
      return editProfileAllowedRoles.includes(userRole);
    },
  },
};
</script>

<style lang="scss" scoped></style>