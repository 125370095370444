<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-full is-flex is-align-content-center">
        <div class="title is-4 mb-0 has-text-blue">
          {{ professionalDataTranslation.professionalProfile }}
        </div>

        <!-- Botón de Edición (solo visible para usuarios con permisos) -->
        <a v-if="canEditProfile" class="ml-2" @click="$emit('editprofessionaldataform')">
          <b-tooltip animated class="is-flex is-align-items-end" label="Editar perfil general" position="is-top"
            type="is-success">
            <b-icon class="icons" icon="pen-to-square"></b-icon>
          </b-tooltip>
        </a>
      </div>

      <!-- Resume -->
      <div class="column is-6">
        <div class="label">
          {{ professionalDataTranslation.curriculum }}
        </div>
        <div class="overflow-ellipsis">
          <a :href="profile.resume.url" target="_blank" rel="noopener noreferrer">
            Ver curriculum
          </a>
        </div>
      </div>

      <!-- Application link video -->
      <div class="column is-6" v-if="profile.applicationLinkVideo">
        <div class="label">
          {{ professionalDataTranslation.urlVideo }}
        </div>
        <div class="overflow-ellipsis">
          <a :href="profile.applicationLinkVideo" target="_blank" rel="noopener noreferrer">
            {{ profile.applicationLinkVideo }}
          </a>
        </div>
      </div>

      <!-- Application file -->
      <div class="column is-6" v-if="profile.applicationFile">
        <div class="label">
          {{ professionalDataTranslation.uploadFile }}
        </div>
        <div class="overflow-ellipsis">
          <a :href="profile.applicationFile.url" target="_blank" rel="noopener noreferrer">
            {{ profile.applicationFile.name }}
          </a>
        </div>
      </div>

      <!-- Psychometry application date -->
      <div v-if="$hasPermissionsByRole(manageAllCandidatesAllowedRoles)" class="column is-6">
        <div class="label">
          {{ professionalDataTranslation.psychometryApplicationDate }}
        </div>
        <div v-if="profile.psychometricTest">
          {{ profile.psychometricTest.appliedAt | formatDate }}
        </div>
        <div v-else>No ha aplicado la psicometría</div>
      </div>

      <!-- Portfolio -->
      <div class="column is-6">
        <div class="label">
          {{ professionalDataTranslation.portfolio }}
        </div>
        <div class="overflow-ellipsis">
          <a :href="profile.portfolio" target="_blank" rel="noopener noreferrer">
            {{ profile.portfolio }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { manageAllCandidatesAllowedRoles } from '@/lib/permissions';
import { editProfileAllowedRoles } from '@/lib/permissions'; // Importa los roles permitidos

export default {
  props: {
    profile: {
      default: () => ({}),
      required: true
    },
  },
  data() {
    return {
      manageAllCandidatesAllowedRoles
    };
  },
  computed: {
    professionalDataTranslation() {
      return this.$t("screens.candidate.detail");
    },
    canEditProfile() {
      const userRole = this.$store.state.session.user.role;
      return editProfileAllowedRoles.includes(userRole);
    },

  },
};
</script>

<style lang="scss" scoped></style>