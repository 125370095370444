export default {
  sections: {
    GeneralData: {
      title: "Información Principal",
      fields: {
        vacancyName: {
          label: "Nombre de la vacante*",
          validation: "required",
        },
        duration: {
          label: "Duración*",
          validation: "required",
        },
        positions: {
          label: "Número de Posiciones",
          validation: "required",
        },
        state: {
          label: "Estado*",
          validation: "required",
        },
        city: {
          label: "Ciudad",
        },
        schedule: {
          label: "Horario",
        },
        showScholarship: {
          label: "Mostrar Beca",
        },
        scholarship: {
          label: "Beca",
        },
        modality: {
          label: "Modalidad",
        },
      },
    },
    AcademicData: {
      title: "Formación Académica",
      fields: {
        degrees: {
          label: "Licenciatura(s)",
          placeholder: "Presione 'Enter' para agregar",
        },
        academicLevel: {
          label: "Nivel académico",
        },
        excelLevel: {
          label: "Nivel de Excel*",
          validation: "required",
        },
        subjectCompletion: {
          label: "Termino de Materias",
        },
        graduation: {
          label: "Titulación",
        },
      },
    },
    LanguageData: {
      title: "Idiomas de la Vacante",
      fields: {
        english: {
          label: "Inglés",
          proficiency: {
            desirable: "Deseable",
            basic: "Básico",
            intermediate: "Intermedio",
            advanced: "Avanzado",
            representative: "Representativa",
            writing: "Escritura",
            reading: "Lectura",
            listening: "Escucha",
            speaking: "Conversación",
          },
        },
      },
    },
    Habilities: {
      title: "Habilidades y Conocimientos",
      fields: {
        skills: {
          label: "Habilidades",
          placeholder:
            "Habilidades, competencias y características personales necesarias para la posición.",
        },
        knowledge: {
          label: "Conocimientos",
          placeholder:
            "Conocimientos como paquetería, programas, equipos, etc., necesarios para la posición.",
        },
      },
    },
    Description: {
      title: "Descripción de la Práctica",
      fields: {
        objective: {
          label: "Objetivo",
        },
        benefits: {
          label: "Beneficios",
        },
        dailyActivities: {
          label: "Actividades Diarias",
        },
      },
    },
    contactData: {
      title: "Datos de Contacto",
      fields: {
        selectedContact: {
          label: "Contacto seleccionado",
          options: ["Sin Asignar"],
        },
        addOtherContacts: {
          label: "Agregar otros contactos",
        },
        availableContacts: {
          label: "Contactos disponibles",
        },
      },
    },
    recruitment: {
      title: "Acerca del Reclutamiento",
      fields: {
        requisitionScheme: {
          label: "Esquema de requisición",
        },
        serviceType: {
          label: "Tipo de Servicio",
        },
        claimType: {
          label: "Tipo de reclamación",
        },
      },
    },
    project: {
      title: "Proyecto",
      fields: {
        killerQuestions: {
          label: "Killer Questions",
        },
        comments: {
          label: "Comentarios",
          placeholder: "Comentarios, observaciones, aclaraciones y más...",
        },
        additionalFields: {
          label: "Campos Adicionales",
        },
      },
    },
  },
};
