<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            Formación académica 
            <f-icon icon="fa-regular fa-graduation-cap" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Degree -->
        <div class="column is-half">
          <ValidationProvider
            rules="required"
            name="Licenciatura(s)"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.degree)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-taginput
                ellipsis
                type="is-primary"
                v-model="form.degree"
                icon="graduation-cap"
                placeholder="Presione 'Enter' para agregar"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Academic Level -->
        <div class="column is-half">
          <ValidationProvider
            rules="required"
            name="Nivel académico"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.academicLevel)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <div class="academicOptions">
                <b-checkbox
                  v-for="(academicLevel, index) in academicLevels"
                  :key="`academic-level-${index}`"
                  class="academicOptions--option"
                  v-model="form.academicLevel"
                  :native-value="academicLevel.value"
                >
                  {{ academicLevel.text }}
                </b-checkbox>
              </div>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Minimum semester -->
        <div class="column is-half"
          v-if="form.academicLevel.includes('student')"
        >
          <ValidationProvider
            :rules="`required|max_value:${form.maximumSemester}`"
            name="Semestre Mínimo"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.minimumSemester)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-if="isMexico"
                expanded
                :placeholder="newPlaceholder(fieldNames.minimumSemester)"
                icon="user-minus"
                v-model="form.minimumSemester"
              >
                <option
                  v-for="semester in semesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>

              <b-select
                v-else
                expanded
                :placeholder="newPlaceholder(fieldNames.minimumSemester)"
                icon="user-minus"
                v-model="form.minimumSemester"
              >
                <option
                  v-for="semester in semesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Maximum semester -->
        <div class="column is-half"
          v-if="form.academicLevel.includes('student')"
        >
          <ValidationProvider
            :rules="`required|min_value:${form.minimumSemester}`"
            name="Semestre Máximo"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.maximumSemester)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-if="isMexico"
                expanded
                :placeholder="newPlaceholder(fieldNames.maximumSemester)"
                icon="user-plus"
                v-model="form.maximumSemester"
              >
                <option
                  v-for="semester in semesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>

              <b-select
                v-if="isSpain"
                expanded
                :placeholder="newPlaceholder(fieldNames.maximumSemester)"
                icon="user-plus"
                v-model="form.maximumSemester"
              >
                <option
                  v-for="semester in semesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Excel Level -->
        <div class="column is-half">
          <ValidationProvider
            rules="required"
            name="Nivel de Excel"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(fieldNames.excelLevel)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                expanded
                icon="browsers"
                :placeholder="newPlaceholder(fieldNames.excelLevel)"
                v-model="form.excelLevel"
              >
                <option
                  v-for="(level, index) in levels"
                  :key="`excelLevel-${index}`"
                  :value="level"
                >
                  {{ level }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-half"></div>

        <!-- Graduate date -->
        <div class="column is-half">
          <ValidationProvider
            rules="required"
            name="Termino de Materias"
            v-slot="{ errors, valid }"
          >
            <b-tooltip
              style="width: 100%"
              label="Maximo mes de termino de materias permitido"
              position="is-top"
              animated
            >
              <b-field
                :label="renameField(fieldNames.graduateDate)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datepicker
                  append-to-body
                  :placeholder="newPlaceholder(fieldNames.graduateDate)"
                  trap-focus
                  type="month"
                  icon="calendar-range"
                  v-model="form.graduateDate"
                ></b-datepicker>
              </b-field>
            </b-tooltip>
          </ValidationProvider>
        </div>

        <!-- Degree date -->
        <div class="column is-half">
          <ValidationProvider
            rules="required"
            name="Titulación"
            v-slot="{ errors, valid }"
          >
            <b-tooltip
              style="width: 100%"
              label="Maximo mes de titulación permitido"
              position="is-top"
              animated
            >
              <b-field
                :label="renameField(fieldNames.degreeDate)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datepicker
                  trap-focus
                  type="month"
                  icon="calendar-range"
                  :placeholder="newPlaceholder(fieldNames.degreeDate)"
                  v-model="form.degreeDate"
                >
                </b-datepicker>
              </b-field>
            </b-tooltip>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import { academicLevels } from "@promeritum/promeritum-utils";
  import { getSemestersList } from "@/lib/semesters";

  import {
    levelOptions,
    getDefaultVacancyFields,
  } from "@/lib/vacancyDictionaries";

  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    props: {
      academicData: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.academicData) {
        this.form = this.academicData;
      }
    },
    data() {
      return {
        academicLevels,
        form: {
          degree: null,
          academicLevel: [],
          minimumSemester: 0,
          maximumSemester: 0,
          excelLevel: null,
          graduateDate: null,
          degreeDate: null,
        },
      };
    },
    watch: {
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      semesters() {
        return getSemestersList(process.env.VUE_APP_LOCATION);
      },
      levels() {
        return levelOptions;
      },
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      isMexico() {
        return process.env.VUE_APP_LOCATION === "mx";
      },
      isSpain() {
        return process.env.VUE_APP_LOCATION === "es";
      },
    },
    methods: {
      async mountedPromises() {
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .academicOptions--option {
    margin-top: 2px;
  }
</style>
